$iService-plava: #00A6CE;
$iService-black-ii: #007D9B;
$iService-zelena: #A5E5D9;
$iService-white: #FFFFFF;
$iService-cool-gray: #636569;
$iService-light-gray: #BDBDBD;
$iService-ligher-gray: #F2F2F2;
$iservice-pink: #F1B9DF;
$iservice-dark-green: #05404e;
$iService-black: #000000;
$iService-black-ii: #000000;
$iservice-new-blue: #0070c9;
$iservice-new-blue-hover: #3b6eab;


/* BOOTSTRAP */

.bg-primary {
    background: $iService-black !important;
}
.bg-secondary {
    background: $iService-black-ii !important;
}
.bg-success {
    background: $iService-zelena !important;
}
.bg-light {
    background: $iService-ligher-gray !important;
}
.bg-danger {
    background: #cf3227 !important;
}
.bg-dark-green {
    background: $iservice-dark-green;
}
.bg-pink {
    background: $iservice-pink !important;
}
.text-primary {
    color: $iService-black !important;
}
.text-secondary {
    color: $iService-black !important;
}
.text-success {
    color: #28a745 !important;
}
.text-light {
    color: $iService-light-gray !important;
}
.text-dark {
    color: $iService-black;
}
.text-blue {
    color: $iservice-new-blue;
}
.text-small {
    font-size: 12px;
    line-height: 14px;
}
.text-normal {
    font-size: 14px;
}
.text-form-placeholder {
    line-height: 48px;
}
.form-control {
    border: none;
    border-radius: 4px;
}
.form-control::placeholder {
    color: $iService-light-gray;
}

.border-primary {
    border-color: $iService-black !important;
}
.vertical-middle {
    position: relative;
}
@media (min-width: 768px) {
    .vertical-middle {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
}
/******* FORMS ********/

.blue-form {

    padding-top: 15px;

    .form-control {
        margin: 15px 0;
    }
    input[type="checkbox"] {
        display: inline;

    }
    label {
        display: inline;
    }
    button {
        margin: 15px 0;
    }

}
.form-control.form-grey {
    background: $iService-ligher-gray;

}
.form-control {
    padding: 1rem 0.75rem !important;
    height: calc(2.25rem + 16px);
}

/* CUSTOM */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-family: 'Poppins', sans-serif;
}
body {
    font-family: 'Roboto', sans-serif;
    color: $iService-black;
    line-height: 1.5em;
    font-size: 16px;
    padding-top: 136px !important;
}
@media (min-width: 992px) {
    body {
        padding-top: 144px !important;
    }
}
/****** FONTS ********/
h1 {
    color: $iService-black;
    font-size: 48px !important;
    letter-spacing: -0.02em;
    font-weight: 600;
}
.h1 {
    color: $iService-white;
    font-size: 48px !important;
    letter-spacing: -0.02em;
    font-weight: 600;
}
h2 {
    color: $iService-black;
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;
}
.h2 {
    color:  $iService-white;
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;
}
h3 {
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $iService-black;
}
.h3 {
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $iService-white;
}
.h4 {
    font-weight: 600;
    line-height: 29px;
    font-size: 24px !important;
    color: $iService-black;
}
.h5 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: $iService-black;
}
.light-text {
    color: rgba(99, 101, 105, 0.5);
}
.postotak {
    font-weight: 500;
    font-size: 72px;
    letter-spacing: -0.02em;
}
.why-choose-iservice {
    padding-bottom: 168px !important;
    background: $iService-black;
}
.navbar-toggler {
    margin-bottom: 8px;
    padding: 0.6rem 0.75rem;
}
/****** TABS ********/

.search-container {
    margin-top: -64px;
    #myTabContent {
        background: $iService-white;
    }
}

.best-offers-container {
    margin-top: -136px;
    #myTabContent {
        background: $iService-white;
    }
}
#iService-offers {
    .tab-content {
        margin-top: -4px;
        border-top: 4px solid $iService-cool-gray;
    }

    .nav-tabs .nav-link {
        border-radius: 4px 4px 0 0;
        color: $iService-white;
        font-size: 24px;
        letter-spacing: 0.01em;
        font-weight: 400;
        padding: 23px 23px 17px 20px;
        border: none;
        position: relative;
        outline: none;
    }

    .nav-tabs .nav-link.active {
        background: $iService-black-ii;
        color: $iService-white;
        font-weight: 700;
        position: relative;
        border-bottom: 4px solid $iService-cool-gray;
        box-sizing: border-box;
    }

    .nav-tabs .nav-link.active:after {
        content: '';
        /*border-top: 40px solid red;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        bottom: 0;
        content: "";*/
        position: absolute;
        left: 45%;
        margin-left: -21px;
        width: 0;
        height: 0;
    }

    .line {
        height: 20px;
        border-bottom: solid 2px #000;
        margin-top: 100px;
        position: relative;
        z-index: 1;
    }

    .nav-tabs .nav-link.active .arrow-triangle {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 10px solid $iService-black-ii;

        position: absolute;
        top: 100%;
        right: 20px;
        z-index: 199;
    }

    .nav-tabs .nav-link.active .arrow-border {
        content: "";
        position: absolute;
        top: 100%;
        right: 20px;
        height: 10px;
        width: 30px;
        z-index: 200;
    }

    .nav-tabs .nav-link.active .arrow-border .pin {
        width: calc(50% + 3px);
        height: 4px;
        background: $iService-cool-gray;
        display: inline-block;
        float: left;
        transform: rotate(25deg);
        transform-origin: 0 0;
    }

    .nav-tabs .nav-link.active .arrow-border .pin:last-child {
        transform: rotate(-25deg);
        transform-origin: 100% 0;
        float: right;
        margin-top: -4px;
    }
}

#iService-product {
    .tab-content {
        margin-top: -4px;
        border-top: 4px solid $iService-cool-gray;
    }

    .nav-tabs .nav-link {
        border-radius: 4px 4px 0 0;
        color: $iService-black;
        font-size: 24px;
        letter-spacing: 0.01em;
        font-weight: 400;
        padding: 23px 48px 17px 20px;
        border: none;
        position: relative;
        outline: none;
    }

    .nav-tabs .nav-link.active {
        background: $iService-black;
        color: $iService-white;
        font-weight: 700;
        position: relative;
        border-bottom: 4px solid $iService-cool-gray;
        box-sizing: border-box;
    }

    .nav-tabs .nav-link.active:after {
        content: '';
        /*border-top: 40px solid red;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        bottom: 0;
        content: "";*/
        position: absolute;
        left: 45%;
        margin-left: -21px;
        width: 0;
        height: 0;
    }

    .line {
        height: 20px;
        border-bottom: solid 2px #000;
        margin-top: 100px;
        position: relative;
        z-index: 1;
    }

    .nav-tabs .nav-link.active .arrow-triangle {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 10px solid $iService-black;

        position: absolute;
        top: 100%;
        right: 20px;
        z-index: 199;
    }

    .nav-tabs .nav-link.active .arrow-border {
        content: "";
        position: absolute;
        top: 100%;
        right: 20px;
        height: 10px;
        width: 30px;
        z-index: 200;
    }

    .nav-tabs .nav-link.active .arrow-border .pin {
        width: calc(50% + 3px);
        height: 4px;
        background: $iService-cool-gray;
        display: inline-block;
        float: left;
        transform: rotate(25deg);
        transform-origin: 0 0;
    }

    .nav-tabs .nav-link.active .arrow-border .pin:last-child {
        transform: rotate(-25deg);
        transform-origin: 100% 0;
        float: right;
        margin-top: -4px;
    }
}
.product-not-in-stock{
    filter: grayscale(100%);
    opacity: 0.5;
}
#support-info {

    .nav-tabs {
        background: $iService-black;
        border-bottom: 1px solid $iService-black;

        .nav-item {
            margin-bottom: 0;

            .nav-link {
                color: $iService-white;
                background: $iService-black;
                border-radius: 0;
                font-size: 16px;
                text-align: center;
                letter-spacing: 0.04em;
                border: none;
                opacity: 0.3;
                &.active{
                    color: $iService-white;
                    opacity: 1;
                }
            }
        }


    }

    .tab-content {
        background: $iService-black;
    }


}

.centered-pills {
    text-align:center;

    ul.nav-pills {
        display:inline-block;

        li {
            display: inline-block;
        }
    }
}

#services-tab
{


    &.nav-pills {
        a.nav-link{
            color: $iService-light-gray;
            line-height: 1.2em;
            &.active {
                background: none;
                color: $iService-black;
            }
        }
    }


    .nav-pills .nav-link {
        &.active{
            background-color: $iService-white;
            font-weight: bold;
            line-height: 17px;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: $iService-black;
        }

    }
    .nav-pills .nav-link {
        border-radius: 0;
        font-weight: bold;
        line-height: 17px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #E0E0E0;
    }
    .nav-pills .nav-link.active .numbers.active {
        color: $iService-zelena;
    }
    .numbers {
        font-weight: normal;
        line-height: normal;
        font-size: 48px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #F2F2F2;
    }

}
#services-tabContent {
    color: #ffffff;
    border-radius: 4px;
    background: $iService-black;
}

/* NAVIGATION */

.navbar-brand {
    max-width: 228px;
    width: 100%;
}
.navbar-brand img.logo {
    width: 100%;
}
.navigation-mobile-fixed {
    background: $iService-white;
    z-index: 1030;
    position: fixed;
    top: 0;
}
.navigation-fixed {
    background: $iService-white;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@media (min-width: 992px) {
    .navigation-fixed {
        top: 0;
        bottom: auto;
    }
}

#userDropdown.dropdown-toggle::after, #cartDropdown.dropdown-toggle::after, #searchDropdown.dropdown-toggle::after {
    display:none;
}

.shop-icons-of-trust .h2 {
    color: $iService-black;
}

.mobile-mini-navigation {
    display:block;
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 1040;
    ul {
        list-style-type: none;
        li{
            display:inline-block;
            padding: 0 10px;

            .mobile-search {
                width: 100%;
                max-width: 155px;
            }
        }
    }
}
@media (min-width: 992px) {
    .mobile-mini-navigation{
        display: none;
    }
}

/*** CATALOG ***/

.list-group {

    :focus {outline:none;}
    ::-moz-focus-inner {border:0;}

    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        //border-top: 1px solid $iService-zelena;
    }
    .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

    }

    .list-group-item {
        border-left: none;
        border-right: none;
        border-top: 1px solid $iService-zelena;
        border-bottom: 1px solid $iService-zelena;
        padding-left: 0;
        padding-right: 0;

        &.parent-category {
            color: $iService-black;
            font-weight: bold;
            letter-spacing: 0.01em;
        }
        &.parent-category:hover {
            color: $iService-black;
            border-bottom: 1px solid $iService-white;
        }
        &.parent-category.collapsed:hover {
            border-bottom: 1px solid $iService-zelena;
        }
        &.parent-category.no-children:hover {
            border-bottom: 1px solid $iService-zelena;
        }
        &.active {
            background: $iService-white
        }
    }
}
.collapse {

    a.list-group-item {
        color: $iService-light-gray;
        border: none !important;
        padding: 0;
        &:hover {
            color: $iService-black;
            border: none;
        }
    }
}

/* PRODUCT */

.products-row:first-child {
    padding-top: 0 !important;
}

.single-product {
    text-align: center;
    border: 1px solid $iService-black;
    border-radius: 4px;
    padding-top: 1rem;

    .title{
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: $iService-black;
        margin: 0 auto;
        display: block;
        display: -webkit-box;
        height: 48px;
        max-width: 144px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .model{
        font-size: 12px;
        letter-spacing: 0.04em;
        color: $iService-light-gray;
    }
    .price{
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.02em;
        color: $iService-black;
    }
    .card-body {
        border-bottom: none !important;
        padding: 0;

    }

    .card-footer {
        padding: 0 1rem;
        background-color: transparent;
        border-top: none;
        padding-bottom: 15px;
    }
}
@media (min-width: 768px) {
    .single-product {
        padding-top: 0;
        .title{
            max-width: 230px;
        }
    }
}

/* BUTTONS */

.btn {
    padding: 12px 50px;
}
.btn-number {
    padding: 12px 5px;
}
.btn-sm {
    padding: 0.25rem 0.5rem;
}
.btn-light, .btn-light:visited {
    background: $iService-ligher-gray;
}
.btn-primary, .btn-primary:visited {
    color: #F2F2F2;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    background: $iService-black;
    //background: radial-gradient(81.69px at 38.67% 4.19%, $iService-black 0%, #F1B9DF 100%);
    border-radius: 4px;
    border: none;
}
.btn-primary:hover, .btn-primary:active {
    background: $iService-cool-gray !important;
}
.btn-primary.disabled, .btn-primary:disabled {
    color:#fff;
    background-color: $iService-black;
    border-color: $iService-black;
}
.btn-outline-primary, .btn-outline-primary:visited {
    border: 1px solid $iService-black;
    color: $iService-black;
}
.btn-outline-primary:hover, .btn-outline-primary:active {
    border: 1px solid $iService-zelena;
    color: $iService-zelena;
    background-color: $iService-white;
}
.btn-link, .btn-link:visited {
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    font-size: 24px;
    color: $iService-black;
    border-bottom: 1px solid $iService-black !important;
    padding-bottom: 10px !important;
    text-align: left;
    width: 100%;
}
.btn.btn-link {
    border-radius: 0!important;
}
.btn-link:hover, .btn-link:active {
    color: $iService-cool-gray;
    text-decoration: none !important;
}
.btn-link:visited {
    text-decoration: none !important;
}
.btn-secondary, .btn-secondary:visited {
    font-weight: 900;
    color: $iService-black;
    background-color: $iService-white;
    border-color: $iService-white;
    border-radius: 4px;
}
.btn-secondary:hover, .btn-secondary:active {
    color: $iService-white;
    border-color: $iservice-new-blue-hover;
    background-color: $iservice-new-blue-hover;
}
.btn-secondary-blue, .btn-secondary-blue:visited {
    font-weight: 900;
    color: $iService-black;
    background-color: $iService-white;
    border-color: $iService-white;
    border-radius: 4px;
}
.btn-secondary-blue:hover, .btn-secondary-blue:active {
    color: $iService-white;
    border-color: $iService-black;
    background-color: $iService-black;
}
.btn-info, .btn-info:visited {
    font-weight: 900;
    color: $iService-white;
    background-color: $iservice-new-blue;
    border-color: $iservice-new-blue;
    border-radius: 4px;
}
.btn-info:hover, .btn-info:active {
    border-color: $iservice-new-blue-hover;
    background-color: $iservice-new-blue-hover;
}
a.link-text-info:link, a.link-text-info:visited {
    color: $iService-black;
    text-decoration: none;
}
a.link-text-info:hover, a.link-text-info:active {
    color: $iservice-new-blue;
}
a.link-text-primary:link, a.link-text-primary:visited {
    color: $iService-white;
    text-decoration: none;
}
a.link-text-primary:hover, a.link-text-primary:active {
    color: $iservice-new-blue;
}
a.link-text-secondary:link, a.link-text-secondary:visited {
    color: $iService-black;
    text-decoration: none;
}
a.link-text-secondary:hover, a.link-text-secondary:active {
    color: $iservice-new-blue;
}
.link-danger, .link.danger:visited {
    transition: color .3s;
    color: $iService-black;
}
.link-danger:hover, .link-danger:active, .link-danger:focus {
    color: #cf3227;
    text-decoration: none
}
#cart_quantity {
    background: $iService-ligher-gray !important;
    height: calc(2.25rem + 12px) !important;
}
.btn-add-to-cart {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
.btn-cart-minus {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.btn-cart-plus {
    border-radius: 0 !important;
}

    /* BORDERS */

.blue-border {
    border-bottom: 1px solid $iService-black;
}
.border-green {
    border-bottom: 1px solid $iService-zelena;
}
hr.border-green {
    border-top: 1px solid $iService-zelena;
    border-bottom: 0;
}
hr.border-white {
    border-top: 1px solid $iService-white;
    border-bottom: 0;
}
.city {
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    font-size: 22px;
    color: $iService-black;
}

/* ACCORDION */

.card {
    border: none;
}
.card-header {
    background-color: transparent;
    padding: 0 !important;
    border-radius: 0 !important;
    border-bottom: none;
}
.card-body {
    border-bottom: 1px solid $iService-black !important;
    padding: 5px 0 20px 0;
}


/* FOOTER */

footer {
    color: $iService-black;
}
footer h6 {
    font-size: 16px;
    letter-spacing: 0.02em;
    color: $iService-black;
    font-weight: 700;
    margin-bottom: 16px !important;
}
footer ul {
    list-style-type: none;
    padding-left: 0;
}
footer ul li {
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.04em;
    padding-bottom: 4px;
}
footer p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
}
footer a:link, footer a:visited {
    color: $iService-black;
}
.payment-methods li {
    display: inline-block;
    padding-right: 20px;
}
.social-icons li {
    display: inline-block;
    padding-right: 20px;
}
.social-share-icons {
    button {
        border: none;
        background: none;

        &:hover {
            cursor: pointer;
        }
    }
}
.footer-copy-padding-top p {
    padding-top: 10px;
}
/* QUICK SERVICE FORM */
.slide-repairs {

    .repair-dummy {
        min-width: 100px;
        width: 100%;
        height: 50px;
        background: #F2F2F2;
        border-radius: 4px;
    }
}
.service-summary p {
    margin: 0;
}
.service-summary hr {
    margin: 10px 0;
}
.service-summary {
    color: #C4C4C4;
    line-height: 1.5em;
    margin-left: 0;
}
@media (min-width: 992px) {
    .slide-repairs {

        .repair-dummy {
            max-width: 263px;
        }
    }
}
.service-total, .service-total-price, .service-base-price, .service-pdv-price, .service-subtotal-price {
    color: $iService-black;
}
.service-shipping, .service-return-price {
    font-weight: bold;
    letter-spacing: 0.02em;
    //color: #78ECD7;
    color: #dc3545;
}
.quick-service-description {
    p {
        margin-bottom: 0;
    }
}
#quick-service-form label {
    font-size: 16px;
    color: $iService-black;
    margin-top: 10px;
}
@-webkit-keyframes leftRight{
    0%{right: 0;}
    100%{right: 33px;}
}

.slick-slide img{
    &.swipe-icon {
        position: absolute;
        padding-top: 15px;
        animation: leftRight 3s infinite;
        -webkit-animation: leftRight 3s infinite;
    }
    &.swipe-icon:after {
        clear:both;
    }



}
.swipe-container {
    display: block;
    width: 48px;
    position: relative;
    min-height: 51px;
    margin: 0 auto;
}
@media (min-width: 992px) {
    .swipe-container{
        display: none;
    }
}

.discount-price {
    position: relative;
    min-height: 30px;
    min-width: 40px;
    .price {
        position: absolute;
        top: 5px;
        right: -5px;
        color: $iservice-pink;
        font-weight: 900;
    }
    .euro-price {
        position: absolute;
        top: 30px;
        right: -5px;
        color: $iservice-pink;
        font-size: 0.8em;
        line-height: 12px;
    }
    .badge {
        position: absolute;
        top: -16px;
        right: -5px;
        font-weight: bold;
        &.badge-info {
            background: $iservice-pink !important;
        }
    }
    .old-price {
        position: absolute;
        top: -15px;
        right: 25px;
        text-decoration: line-through;
        font-size: 0.8em;
        line-height: 12px;
    }
}
@media (min-width: 992px) {
    .discount-price {
        .badge {
            right: -10px;
        }
    }
}

/* #quick-service-form Select styles */

#quick-service-form select {
    border-radius: 4px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    background: url("../images/arrow-down.svg") no-repeat right 15px center $iService-ligher-gray;
}

#quick-service-form select:focus {
    outline: none !important;
}

/* #quick-service-form Checkboxes styles */
#quick-service-form input[type="checkbox"] { display: none; }

#quick-service-form input[type="checkbox"] + label {
    display: block;
    position: relative;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: $iService-black;
    padding: 15px 0 0 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

#quick-service-form input[type="checkbox"] + label:last-child { margin-bottom: 0; }

#quick-service-form input[type="checkbox"] + label:before {
    content: '';
    display: table-row !important;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 4px;
}
#quick-service-form input[type="checkbox"] + label {
    border-radius: 4px;
    font-size: 14px;
    line-height: 28px;
    color: $iService-black;
    height: 100%;
    cursor: pointer;
    background: $iService-ligher-gray;
}
/*#quick-service-form input[type="checkbox"]:checked + label::before {
    background: $iService-zelena;
    width: 100%;
    border-radius: 4px;
    opacity: 1;
    height: 100%;
}*/
#quick-service-form input[type="checkbox"]:checked + label {
    background: $iservice-new-blue;
    color: #FFFFFF;
    width: 100%;
    border-radius: 4px;
    opacity: 1;
    height: 100%;

    .price {
        color: $iService-black;
    }
    .euro-price {
        color: $iService-black;
    }
    .badge {
        &.badge-info {
            background: $iService-black !important;
        }
    }
}

/* #quick-service-form INPUT styles */
#quick-service-form input[type="text"] {
    border-radius: 4px;
    border: none;
    background: $iService-ligher-gray;
}
#quick-service-form .cell-phone-background {
    background: $iService-ligher-gray;
    border-radius: 25px;
    width: 230px;
    height: 470px;
    position: relative;
}
#quick-service-form .cell-phone-background p {
    margin: 0 auto;
    max-width: 122px;
    text-align: center;
    padding-top: 40px;
}
#quick-service-form .cell-phone-background .cell-phone-button {
    background: #E0E0E0;
    position: absolute;
    bottom: 20px;
    left:50%;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-left: -17.5px;
}

#quick_service_form_repairs, #quick_service_device_form_repairs, #quick_service_manufacturer_form_repairs {
    display: table;
    padding-left: 0;
    width: 100%;
    border-spacing: 0 10px;
}
#quick_service_form_repairs label, #quick_service_device_form_repairs label, #quick_service_manufacturer_form_repairs label {
    display: table-row !important;
}

#quick_service_form_repairs label td, #quick_service_device_form_repairs label td, #quick_service_manufacturer_form_repairs label td {
    padding: 10px 5px;
    margin-bottom: 5px;
}

#quick_service_form_repairs label td:first-child, #quick_service_device_form_repairs label td:first-child, #quick_service_manufacturer_form_repairs label td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
#quick_service_form_repairs label td:last-child, #quick_service_device_form_repairs label td:last-child, #quick_service_manufacturer_form_repairs label td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.cell-phone-image img {
    display: block;
    margin: 0 auto;
}

/******* SHOP ********/

.shop-info h3 {
    font-weight: 500;
    line-height: 31px;
    font-size: 24px;
    text-align: center;
    color: $iService-black;
}

.action {
    color: $iService-white;
    position: relative;
    width: 100%;
    border-radius: 4px;
    text-align: center;

    .content {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
    }

}

.dropdown-menu {
    padding: 0 !important;
    border: none;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    line-height: 1.1em;

    &.small-dropdown {
        left: auto;
        right: 0;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);

        .pay-button {
            width: 100%;
            max-width: 242px;
        }
    }

}

.megamenu {
    color: $iService-white;
    position: relative;
    width: 100%;
    height: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-weight: 400;
    font-size: 14px;
    border-bottom-right-radius: 4px;

    .shop-dropdown-content {
        padding: 40px;

    }

}

.service-items {
    list-style-type: none;
    padding-left: 0;
    font-weight: 400;
}
.dropdown-item:hover, .dropdown-item:active {
    color: $iService-black;
    background: $iService-white;
}

/***** SUPPORT *****/

#support-hero-container {
    background: url('../images/support/podrska-desktop.jpg') no-repeat;
    border-radius: 4px;
    color: $iService-white;
    min-height: 480px;
}
/***** COVER IMAGES *****/
#quick-service-hero-container {
    background: url('../images/service/brzi-servis-mobile.jpg') #e8f0f3 no-repeat;
    border-radius: 4px;
    color: $iService-white;
    min-height: 480px;
    padding-top: 400px !important;
}
@media (min-width: 576px) {
    #quick-service-hero-container {
        background: url('../images/service/brzi-servis-tablet.jpg') no-repeat;
        padding-top: 0 !important;
    }
}
@media (min-width: 768px) {
    #quick-service-hero-container {
        background: url('../images/service/brzi-servis-desktop.jpg') no-repeat;
    }
}

#i-business-hero-container {
    background: url("../images/i-business/ibusiness-desktop.jpg");
    border-radius: 4px;
    color: $iService-white;
    min-height: 480px;
    padding-top: 0 !important;

}

.cover-about-us {
    background: url('../images/o-nama.jpg') no-repeat center top;
}
.cover-employment {
    background: url('../images/zaposljavanje.jpg') no-repeat center top;
}
.cover-motherboard {
    background: url('../images/maticna-ploca.jpg') no-repeat center top;
}

/***** iBUSINESS *****/



#i-business-form
{
    height: auto;

    #i-business-form-container{
        z-index: 99;
        background: $iService-black;
        color: $iService-white;
        border-radius: 4px;
    }
}
@media (min-width: 768px) {
    #i-business-form {
        height: 100px;
        margin-top: -100px;
    }
}
#i-business-benefits img {
    border-radius: 4px;
}
.title-green {
    font-weight: bold;
    line-height: 17px;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $iService-black;
}

.ibussiness-radio {
    padding-right: 50px;
}



/***** Employment *****/
#employment-form
{
    height: auto;

    #employment-form-container{
        z-index: 99;
        background: $iService-black;
        color: $iService-white;
        border-radius: 4px;
    }
}
@media (min-width: 768px) {
    #employment-form {
        height: 100px;
        margin-top: -100px;
    }
}

/*** TESTIMONIALS ***/

#testimonials {
    background: url("../images/i-business/testimonials-background.jpg");
    border-radius: 4px;
    color: $iService-white;
    p.h2 {
        margin-left: 64px;
    }
    p.small {
        font-size: 14px;
        color: $iService-zelena;
    }
}
/*** newsletter ***/
.newsletter-container {
    background: $iService-black;
    border-radius: 4px;
}
.newsletter-container input[type="text"] {
    border: 1px solid $iService-white;
}

/*** SHOPPING CART ***/
.cart-wrapper {
    background: $iService-white;
    width: 100%;
    position: absolute;
    top: 0;
    min-height: 100vh;
}
@media (min-width: 768px) {
    .cart-wrapper {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$iService-plava+50,ffffff+50 */
        background: $iService-black; /* Old browsers */
        background: -moz-linear-gradient(left, $iService-black 50%, $iService-white 50%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $iService-black 50%,$iService-white 50%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $iService-black 50%,$iService-white 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$iService-plava', endColorstr='$iService-white',GradientType=1 ); /* IE6-9 */
    }
}


.cart-wrapper {


    .breadcrumb {
        font-size: 12px;
        background: transparent;
        color: $iService-ligher-gray;
    }
    .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.4rem;
    }
    .breadcrumb-item.active {
        color: $iService-white;
        font-weight: bold;
    }
    .breadcrumb-item a:link, .breadcrumb-item a:visited {
        color: $iService-white;
    }
    .breadcrumb-item a:hover, .breadcrumb-item a:active {
        color: $iService-white;
    }
    .breadcrumb-item + .breadcrumb-item::before{
        content: url(../images/divider.svg);
    }
}

.cart-item:first-of-type {
    border-top: 1px solid $iService-black;
}
.cart-item{
    border-bottom: 1px solid $iService-black;
}

.summary-item:first-of-type {
    border-top: 1px solid $iService-zelena;
}
.summary-item{
    border-bottom: 1px solid $iService-zelena;
}
.small-service-repairs {
    list-style-type: none;
}
/*** SLICK SLIDER - EDIT ***/
.slick-dots li.slick-active button::before {
    color: $iService-black !important;
    opacity: 1 !important;
}
.slick-dots li button::before {
    color: $iService-black !important;
    font-size: 14px !important;
}

/*** GOOGLE MAPS ***/
.map-container {
    position: relative;
    width: 100%;
    padding-top: 60%; /* 1:1 Aspect Ratio */
}

#rijeka, #masarykova, #gradaVukovara, #heinzelova {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
#GMapsID div div a div img{
    display:none;
}
// remove the link only
a[title="Report errors in the road map or imagery to Google"] {
    display: none !important;
}

/*** T&C ***/
ol.numbers {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol.numbers > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol.numbers > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    font-weight: bold;
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") " ";
}

ol.lower-alpha {
    list-style-type: lower-alpha !important;
    padding-left: 15px;
}
ul.dashed {
    list-style-type: none;
}
ul.dashed > li {
    text-indent: -5px;
}
ul.dashed > li:before {
    content: "-";
    padding-right: 0.6em;
}

.border-radius-small {
    border-radius: 4px;
}

.border-top-bottom-green {
    border-top: 1px solid $iService-black;
    border-bottom: 1px solid $iService-black;
}

.text-disclaimer {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.04em;
}

.service-model-image {
    max-height: 180px;
    width: auto;
}
.terms-and-conditions-links {

    a:link, a:visited {
        color: $iService-white;
        text-decoration: none;
    }

    a:hover, a:active {
        color: $iservice-new-blue;
    }
}

.failure-height {
    min-height: 121px;
}

.cookie-notification {
    color: $iService-black-ii;
    position: fixed;
    bottom: 74px;
    width: 100%;
    padding: 10px 15px;
    background: #ffffff;
    font-size: 0.8em;
    line-height: 1.1em;

    #close {
        color: $iService-black-ii;
        font-weight: bold;
        cursor: pointer;
    }
    #close:hover {
        color: $iservice-new-blue;
    }

    a:link, a:visited {
        color: $iService-black-ii;
    }
    a:hover, a:active {
        color: $iservice-new-blue;
        text-decoration: none;
    }

}
@media (min-width: 992px) {
    .cookie-notification {
        bottom: 0;
    }
}

.discount-circle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    .circle {
        position: relative;
        font-size: 60px;
        width: 1em;
        height: 1em;
        font-weight: bold;
        border-radius: 50%;
        background: $iservice-pink;
        > span {
            position: absolute;
            width: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            width: 2.5em;
            line-height: 2.4em;
            font-size: 0.4em;
            color: $iService-white;
            display: block;
            text-align: center;
            white-space: nowrap;
        }
    }

}
@media (min-width: 992px) {
    .discount-circle {
        .circle {
            font-size: 100px;
        }

    }
}

.product-discount {
    font-size: 14px;
}

/* ALERTS */
.alert {
    .alert-success {
        color: $iService-white;
        background-color: $iService-zelena;
        border-color: $iService-zelena;

    }
}
ul.user-profile-navigation {

    list-style-type: none;
    padding-left: 0;

    li {
        padding: 10px 0 10px 1rem;
        border-bottom: 1px solid $iService-zelena;
    }
}

.minus-icon-margin {
    margin-top: -5px;
}

.homepage-ribbon {
    p {
        margin-bottom: 0;
        font-size: 13px;
    }
    a:link, a:visited {
        color: $iService-black;
        text-decoration: none;
    }
    a:hover, a:active {
        color: $iService-zelena;
    }
}
.homepage-lower-ribbon {
    margin-bottom: 8px;
    p {
        margin-bottom: 0;
        font-size: 13px;
    }
    a:link, a:visited {
        color: #FFFFFF;
        text-decoration: none;
    }
    a:hover, a:active {
        color: $iService-zelena;
    }
}
@media (min-width: 768px) {
    .homepage-ribbon, .homepage-lower-ribbon {
        p{
            font-size: 16px;
        }
    }
}

.banner-full-width {
    color: #ffffff;
    a:link, a:visited {
        color: #ffffff;
        text-decoration: none;
    }
    a:hover, a:active {
        color: #ffffff;
    }
    p {
        margin-bottom: 0;
    }
}


/* Range slider */
/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

.irs {
    position: relative; display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.irs-line {
    position: relative; display: block;
    overflow: hidden;
    outline: none !important;
    background: #e1e4e9;
}
.irs-line-left, .irs-line-mid, .irs-line-right {
    position: absolute; display: block;
    top: 0;
}
.irs-line-left {
    left: 0; width: 11%;
}
.irs-line-mid {
    left: 9%; width: 82%;
}
.irs-line-right {
    right: 0; width: 11%;
}

.irs-bar {
    position: absolute; display: block;
    left: 0; width: 0;
}
.irs-bar-edge {
    position: absolute; display: block;
    top: 0; left: 0;
}

.irs-shadow {
    position: absolute; display: none;
    left: 0; width: 0;
}

.irs-slider {
    position: absolute; display: block;
    cursor: default;
    z-index: 1;
}
.irs-slider.single {

}
.irs-slider.from {

}
.irs-slider.to {

}
.irs-slider.type_last {
    z-index: 2;
}

.irs-min {
    position: absolute; display: block;
    left: 0;
    cursor: default;
}
.irs-max {
    position: absolute; display: block;
    right: 0;
    cursor: default;
}

.irs-from, .irs-to, .irs-single {
    position: absolute; display: block;
    top: 0; left: 0;
    cursor: default;
    white-space: nowrap;
    font-weight: bold;
}

.irs-grid {
    position: absolute; display: none;
    bottom: 0; left: 0;
    width: 100%; height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
.irs-grid-pol {
    position: absolute;
    top: 0; left: 0;
    width: 1px; height: 8px;
    background: #000;
}
.irs-grid-pol.small {
    height: 4px;
}
.irs-grid-text {
    position: absolute;
    bottom: 0; left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px; line-height: 9px;
    padding: 0 3px;
    color: #000;
}

.irs-disable-mask {
    position: absolute; display: block;
    top: 0; left: -1%;
    width: 102%; height: 100%;
    cursor: default;
    background: rgba(0,0,0,0.0);
    z-index: 2;
}
.irs-disabled {
    opacity: 0.4;
}
.lt-ie9 .irs-disabled {
    filter: alpha(opacity=40);
}


.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}


.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
    background: $iService-black;
}

.irs {
    height: 40px;
}
.irs-with-grid {
    height: 60px;
}
.irs-line {
    height: 12px; top: 25px;
}
.irs-line-left {
    height: 12px;
    background-position: 0 -30px;
}
.irs-line-mid {
    height: 12px;
    background-position: 0 0;
}
.irs-line-right {
    height: 12px;
    background-position: 100% -30px;
}

.irs-bar {
    height: 12px; top: 25px;
    background-position: 0 -60px;
}
.irs-bar-edge {
    top: 25px;
    height: 12px; width: 9px;
    background-position: 0 -90px;
}

.irs-shadow {
    height: 3px; top: 34px;
    background: #000;
    opacity: 0.25;
}
.lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
}

.irs-slider {
    width: 16px; height: 18px;
    top: 22px;
    background-position: 0 -120px;
}
.irs-slider.state_hover, .irs-slider:hover {
    background-position: 0 -150px;
}

.irs-min, .irs-max {
    color: #999;
    font-size: 10px; line-height: 1.333;
    text-shadow: none;
    top: 0; padding: 1px 3px;
    background: #e1e4e9;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
    color: #fff;
    font-size: 10px; line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: $iService-black;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-from:after, .irs-to:after, .irs-single:after {
    position: absolute; display: block; content: "";
    bottom: -6px; left: 50%;
    width: 0; height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: $iService-black;
}


.irs-grid-pol {
    background: #e1e4e9;
}
.irs-grid-text {
    color: #999;
}

.irs-disabled {
}

/*  PAGINATION */
.page-item.active .page-link {
    color: #fff;
    background-color: $iService-black;
    border-color: $iService-black;
}
.page-link:link, .page-link:visited {
    color: $iService-black;
}
.page-link:hover, .page-link:active {
    color: $iService-black;
}

.barcode-svg {
    svg {
        width: 100%;
    }
}

.blog-content {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    img {
        width: 100%;
    }
}

.post-title {
    color: $iService-black;
}
@media (min-width: 768px) {
    .post-title {
        color: #ffffff;
    }
    .blog-content {
        margin-top: -100px;
    }
}

/* SIDEBAR FILTERS */
.plus-minus-link {
    display: block;
    position: relative;
    padding: 14px 0;
    color: $iService-black;
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 12px;
        height: 2px;
        background: $iService-black;
        top: 21px;
        right: 0;
    }
    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 2px;
        height: 12px;
        background: transparent;
        top: 16px;
        right: 5px;
        transition: all .3s;
    }
    &.collapsed {
        color: $iService-black;
        &:after {
            background: $iService-black;
        }
    }
    &:hover {
        color: $iService-black;
        text-decoration: none;
    }
}
@media (min-width: 992px) {
    .plus-minus-link {
        &:before {
            right: 0;
        }
        &:after {
            right: 5px;
        }
    }
}
.filter-link, .filter-link:visited, {
    color: $iService-light-gray;
}
.filter-link:hover, .filter-link:active, .filter-link:focus {
    color: $iService-light-gray;
}

.progress {
    padding: 3px;
}
.progress-bar {
    background-color: $iService-black !important;
}

.color-dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.text-inline{
    white-space: nowrap;
}